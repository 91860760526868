import Api from "../Api";

const END_POINT = 'user/login';

export default {
    login(data) {
        //return Api.get(END_POINT, data);
        return Api.post(END_POINT, {
            'email': data.email,
            'password': data.password,
        });
    }
}