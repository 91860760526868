import { BCol } from 'bootstrap-vue';
<template>
  <div class="container-fluid">
    <div class="row no-gutter">
      <div class="col-md-8 d-none d-md-flex bg-image"></div>
      <div class="col-md-4 bg-light">
        <div class="login d-flex align-items-center">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <b-img
                  width="220px"
                  src="https://yosoyvendedor.com/source/6af50bd2b8e8529f0a2dc2fe5ff2d951/602c2c51ba17d6011.png"
                  center
                  fluid
                  alt="Fluid image"
                  style="margin-top: 45px"
                ></b-img>
                <br />
                <p style="text-align: center">
                  <span style="opacity: 50%">INICIAR SESION </span
                  ><br />¡Bienvenido a FerreProd!
                </p>
                <hr />
                <br />
                <b-form ref="form" @submit.prevent="submit">
                  <b-form-group invalid-feedback="Correo electronico requerido">
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text"
                          ><i class="icon fa fa-envelope fa-lg"></i
                        ></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="LoginInput"
                        size="lg"
                        placeholder="Correo electronico"
                        type="email"
                        v-model="$v.form.email.$model"
                        :state="validateState('email')"
                      >
                      </b-form-input>
                    </b-input-group>
                    <div class="error" v-if="!$v.form.email.required ? $v.form.email.$anyError : null">El campo correo es requerido</div>
                    <div class="error" v-if="!$v.form.email.email ? $v.form.email.$anyError : null">Debe ingresar un correo valido</div>
                  </b-form-group>

                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text"
                          ><i class="icon fa fa-key fa-lg"></i
                        ></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="LoginInput"
                        size="lg"
                        placeholder="Contraseña"
                        type="text"
                        v-model="$v.form.password.$model"
                        
                        v-if="showPassword"
                      >
                      </b-form-input>
                      <b-form-input
                        class="LoginInput"
                        size="lg"
                        placeholder="Contraseña"
                        type="password"
                        v-model="$v.form.password.$model"
                        :state="validateState('password')"
                        v-else
                      >
                      </b-form-input>
                      <b-input-group-prepend>
                        <b-button
                          class="button"
                          @click="toggleShow"
                          variant="dark"
                          style="
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;
                          "
                          ><i
                            class="fas"
                            :class="{
                              'fa-eye-slash': showPassword,
                              'fa-eye': !showPassword,
                            }"
                          ></i
                        ></b-button>
                      </b-input-group-prepend>
                    </b-input-group>
                    <div class="error" v-if="!$v.form.password.required ? $v.form.password.$anyError : null">El campo contraseña es requerido</div>
                    <div class="error" v-if="!$v.form.password.email ? $v.form.password.$anyError : null">Debe ingresar al menos 6 caracteres</div>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      class="text-right"
                      id="checkbox-1"
                      v-model="status"
                      name="checkbox-1"
                      value="accepted"
                      unchecked-value="not_accepted"
                      style="color: gray"
                    >
                      Recordar contraseña
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-button type="submit" variant="dark" block>
                      <!--  <b-button type="submit" variant="dark" block @click="API"> -->
                      Iniciar sesion
                      <b-spinner
                        class="ml-3"
                        small
                        v-show="loading"
                        role="status"
                        aria-hidden="true"
                      ></b-spinner>
                      <span class="sr-only">Iniciando sesion...</span>
                    </b-button>
                  </b-form-group>
                </b-form>

                <p class="left text-center" style="opacity: 50%">V.1.0</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import Login from "../../../api/login/Login";
import Swal from 'sweetalert2'

export default {
  mixins: [validationMixin],
  data() {
    return {
      showPassword: false,
      loading: false,
      status: null,
      form: {
        email: '',
        password: '',
      },
      data_error: {},
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(2),
      },
    },
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    API(){
      if (this.loading) return;
      this.loading = true;

      setTimeout(() => {
      Login.login(this.form).then(response => {
        this.loading = false;
        localStorage.autentication = JSON.stringify(response.data);
        Swal.fire({
          title: 'Bienvenido '+response.data.user.name,
          text: 'Iniciaste sesión correctamente',
          icon: 'success',
        })
        //this.$router.push("/backend/dashboard");
        location.href = "/backend/dashboard";
      })
      .catch((error) => {
           Swal.fire({
            title: 'Que mal!',
            text: error.response.data.message,
            icon: 'error',
          })
          this.loading = false;
      });
      }, 2500);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return false;
      this.API();
    },

  },
};
</script>

<style scoped>
.login,
.image {
  min-height: 100vh;
}

.bg-image {
  background-image: url("../../../assets/img/FERREBAZTAN.jpg");
  background-size: cover;
  background-position: center center;
}
.bg-logo {
  background-image: url("https://ii.ct-stc.com/2/logos/empresas/2019/03/08/4f7e4202a2434289a6c5220305471thumbnail.jpg");
  background-position: center center;
}
.icon {
  color: black;
  opacity: 20%;
}
.input-group-text {
  width: 48px;
  border-right: none;
  background-color: #ffffff;
  color: gray;
  opacity: 80%;
}
[class^="fa-"],
[class*=" fa-"] {
  display: inline-block;
  width: 100%;
}
.LoginInput {
  border-left: none;
  position: relative;
}
.error {
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin: 2px 0 0 45px
}
</style>
